import axios from '@axios'

const PREFIX = '/usuarios'

export default {

  getContadores() {
    return axios.get(`${PREFIX}/contadores`)
  },
}
